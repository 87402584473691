var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{style:(_vm.userData == 'admin' ? '' : 'display: none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Sales By")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":_vm.userData == 'admin' ? 'required' : '',"name":"Sales By"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":['Employee', 'ChannelPartner', 'Franchise', 'Direct']},on:{"input":function($event){return _vm.selecttypee($event)}},model:{value:(_vm.selecttype),callback:function ($$v) {_vm.selecttype=$$v},expression:"selecttype"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.selecttype != 'Direct' ? '' : 'display: none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Select "+_vm._s(_vm.selecttype))]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":_vm.userData == 'employee' || _vm.selecttype == 'Employee' ? 'required' : '',"name":"Employee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.teamOption},on:{"input":function($event){return _vm.userInfo($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var profile_image = ref.profile_image;
var username = ref.username;
var surname = ref.surname;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(name)+" "+_vm._s(surname))]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}],null,true),model:{value:(_vm.selectedteam),callback:function ($$v) {_vm.selectedteam=$$v},expression:"selectedteam"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Booking Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Booking Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }},model:{value:(_vm.bookingdate),callback:function ($$v) {_vm.bookingdate=$$v},expression:"bookingdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Booking Type ")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Booking Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":['Full Amount', 'Part Payment']},on:{"input":function($event){return _vm.handelClickBooking($event)}},model:{value:(_vm.bookingtype),callback:function ($$v) {_vm.bookingtype=$$v},expression:"bookingtype"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.userDetails ? '' : 'display:none'),attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Email")]),_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.userDetails.email),callback:function ($$v) {_vm.$set(_vm.userDetails, "email", $$v)},expression:"userDetails.email"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Phone No")]),_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.userDetails.mobile),callback:function ($$v) {_vm.$set(_vm.userDetails, "mobile", $$v)},expression:"userDetails.mobile"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Username")]),_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.userDetails.username),callback:function ($$v) {_vm.$set(_vm.userDetails, "username", $$v)},expression:"userDetails.username"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Photo :")]),_c('b-media-aside',[_c('b-link',[_c('b-img',{attrs:{"rounded":"","src":_vm.userDetails.profile_image
                        ? _vm.userDetails.profile_image
                        : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg',"height":"80"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Application Form","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("In SIR?")]),_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":['Yes', 'No']},on:{"input":_vm.handleSIR},model:{value:(_vm.sir),callback:function ($$v) {_vm.sir=$$v},expression:"sir"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Village Name (New Survay No.)")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Village Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"villagename","options":_vm.villageOption},on:{"input":function($event){return _vm.getVillageById($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var villagename = ref.villagename;
                        var surveynonew = ref.surveynonew;
return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(villagename)+" ")]),_c('span',[_vm._v("("+_vm._s(surveynonew)+")")])]}}],null,true),model:{value:(_vm.village),callback:function ($$v) {_vm.village=$$v},expression:"village"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.sir == 'Yes' ? '' : 'display:none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Zone")]),_c('b-form-input',{attrs:{"placeholder":"Zone"},model:{value:(_vm.zone.name),callback:function ($$v) {_vm.$set(_vm.zone, "name", $$v)},expression:"zone.name"}})],1)],1),_c('b-col',{style:(_vm.sir == 'Yes' ? '' : 'display:none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Selelct T.P.")]),_c('b-form-input',{attrs:{"placeholder":"Tp No.","disabled":""},model:{value:(_vm.tpno),callback:function ($$v) {_vm.tpno=$$v},expression:"tpno"}})],1)],1),_c('b-col',{style:(_vm.sir == 'Yes' ? '' : 'display:none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Selelct Sub T.P.")]),_c('b-form-input',{attrs:{"placeholder":"Sub Tp No.","disabled":""},model:{value:(_vm.subtpno),callback:function ($$v) {_vm.subtpno=$$v},expression:"subtpno"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Survey No. New")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Survey No. New"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"New Survay No.","disabled":""},model:{value:(_vm.newsurveyno),callback:function ($$v) {_vm.newsurveyno=$$v},expression:"newsurveyno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Old Survay No.")]),_c('b-form-input',{attrs:{"placeholder":"Old Survay No.","disabled":""},model:{value:(_vm.oldsurveyno),callback:function ($$v) {_vm.oldsurveyno=$$v},expression:"oldsurveyno"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("F.P. No.")]),_c('b-form-input',{attrs:{"placeholder":"Enter F.P. No.","disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.fpno),callback:function ($$v) {_vm.fpno=$$v},expression:"fpno"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("F.P. Area /Land size (In Sq. Yard)")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Land Size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter F.P. Area","type":"number","disabled":""},model:{value:(_vm.fparea),callback:function ($$v) {_vm.fparea=$$v},expression:"fparea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Rate Details","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("CP Rate")]),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Enter Rate"},model:{value:(_vm.cprate),callback:function ($$v) {_vm.cprate=$$v},expression:"cprate"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Bulk Land Rate")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Bulk Land Rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Enter Rate","type":"number"},model:{value:(_vm.landrate),callback:function ($$v) {_vm.landrate=$$v},expression:"landrate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("With NA?")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"With NA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":"","placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Yes', 'No']},model:{value:(_vm.withna),callback:function ($$v) {_vm.withna=$$v},expression:"withna"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.withna == 'No' ? '' : 'display:none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("NA Charges Per Bigha")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":_vm.withna == 'No' ? 'required' : '',"name":"Charges Per Bigha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","placeholder":"Enter NA Charges"},model:{value:(_vm.nacharges),callback:function ($$v) {_vm.nacharges=$$v},expression:"nacharges"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Remarks")]),_c('b-form-textarea',{attrs:{"id":"remarks","placeholder":"Enter remarks","rows":"1"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Land Jantri Rate/ Govt. Guideline Rs.")]),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Enter Land Jantri Rate"},model:{value:(_vm.jantrirate),callback:function ($$v) {_vm.jantrirate=$$v},expression:"jantrirate"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Payment Type")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Payment Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Down Payment', 'Token']},on:{"input":_vm.handleToken},model:{value:(_vm.paymenttype),callback:function ($$v) {_vm.paymenttype=$$v},expression:"paymenttype"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.paymenttype == 'Token' ? '' : 'display:none'),attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Payment Token")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":_vm.paymenttype == 'Token' ? 'required' : '',"name":"Payment Token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Payment Token"},on:{"input":_vm.handleToken},model:{value:(_vm.paymenttoken),callback:function ($$v) {_vm.paymenttoken=$$v},expression:"paymenttoken"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Payment Terms Days")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Payment Terms Days"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Enter Payment Terms Days"},model:{value:(_vm.paymenttermday),callback:function ($$v) {_vm.paymenttermday=$$v},expression:"paymenttermday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Payment Through")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Payment Through"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.paymentThroughOption},on:{"input":_vm.handleSelectPayment},model:{value:(_vm.paymentthrough),callback:function ($$v) {_vm.paymentthrough=$$v},expression:"paymentthrough"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Payment Details","no-body":""}},[_c('b-col')],1)],1),(_vm.noChequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Bank Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Bank Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Bank Name"},model:{value:(_vm.bankname),callback:function ($$v) {_vm.bankname=$$v},expression:"bankname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,85774447)})],1)],1):_vm._e(),(_vm.noChequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Reference Number")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Reference Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Reference Number"},model:{value:(_vm.refno),callback:function ($$v) {_vm.refno=$$v},expression:"refno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,190631511)})],1)],1):_vm._e(),(_vm.noChequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Upload Receipt")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Upload Receipt "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Select Document","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.handleMultiFileChange($event, 'sales', 'receipt')}},model:{value:(_vm.bankreceipt),callback:function ($$v) {_vm.bankreceipt=$$v},expression:"bankreceipt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2419554801)})],1),_c('attachment',{attrs:{"data":_vm.bankreceipt}})],1):_vm._e(),(_vm.noChequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }},model:{value:(_vm.bankdate),callback:function ($$v) {_vm.bankdate=$$v},expression:"bankdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3232433971)})],1)],1):_vm._e(),(_vm.noChequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Remarks")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Remarks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Remarks","state":errors.length > 0 ? false : null,"rows":"1"},model:{value:(_vm.bankremarks),callback:function ($$v) {_vm.bankremarks=$$v},expression:"bankremarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4066718765)})],1)],1):_vm._e(),(_vm.chequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Cheque No")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Cheque No"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Cheque No"},model:{value:(_vm.chequeno),callback:function ($$v) {_vm.chequeno=$$v},expression:"chequeno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3884361775)})],1)],1):_vm._e(),(_vm.chequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Bank Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Bank Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Bank Name"},model:{value:(_vm.bankname),callback:function ($$v) {_vm.bankname=$$v},expression:"bankname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,85774447)})],1)],1):_vm._e(),(_vm.chequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Cheque Date:")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Cheque Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }},model:{value:(_vm.chequedate),callback:function ($$v) {_vm.chequedate=$$v},expression:"chequedate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1209895546)})],1)],1):_vm._e(),(_vm.chequeField)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":['Pending', 'Bounce', 'Clear']},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2158181033)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}}),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Total Payment")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Total Payment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Total Payment","disabled":""},model:{value:(_vm.totalpayment),callback:function ($$v) {_vm.totalpayment=$$v},expression:"totalpayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("First Payment")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":_vm.firstPaymentRules,"name":"First Payment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Enter First Payment"},on:{"input":_vm.handleRemaining},model:{value:(_vm.firstpayment),callback:function ($$v) {_vm.firstpayment=$$v},expression:"firstpayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] == "The First Payment field must be 10000 or more" ? "The First Payment field must be accept more than 5 figure Amount" : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Remaining Payment")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Remaining Payment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Remaining Payment","disabled":""},model:{value:(_vm.remainingpayment),callback:function ($$v) {_vm.remainingpayment=$$v},expression:"remainingpayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{style:(_vm.bookingtype == 'Part Payment' ? '' : 'display:none'),attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"INSTALLMENTS","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{style:(_vm.bookingtype == 'Part Payment' ? '' : 'display:none'),attrs:{"md":"12"}},[_c('table',{staticClass:"table table-bordered"},[_c('thead',{staticClass:"text"},[_c('tr',[_c('th'),_c('th',[_vm._v("Installment date")]),_c('th',[_vm._v("Installment Amount")]),_c('th',[_vm._v("Remarks")]),_c('th',{style:(_vm.bookingtype == 'Part Payment' ? '' : 'display:none')},[_vm._v(" Action ")])])]),_c('tbody',_vm._l((_vm.installments),function(installment,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                      dateFormat: 'd/m/Y',
                      defaultDate: new Date(),
                    }},model:{value:(installment.installment_date),callback:function ($$v) {_vm.$set(installment, "installment_date", $$v)},expression:"installment.installment_date"}})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Enter Installment Amount"},model:{value:(installment.installment_amount),callback:function ($$v) {_vm.$set(installment, "installment_amount", $$v)},expression:"installment.installment_amount"}})],1),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Enter Installment Remarks"},model:{value:(installment.remarks),callback:function ($$v) {_vm.$set(installment, "remarks", $$v)},expression:"installment.remarks"}})],1),_c('td',{style:(_vm.bookingtype == 'Part Payment' ? '' : 'display:none')},[_c('b-button',{staticClass:"mt-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)])}),0)]),_c('b-button',{staticClass:"mt-1",style:(_vm.bookingtype == 'Part Payment' ? '' : 'display:none'),attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_vm._v(" Add more ")])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card-code',{attrs:{"title":"Customer Details","no-body":""}},[_c('b-col')],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Title")]),_c('v-select',{attrs:{"placeholder":"None","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":['Shri.', 'Smt.', 'Ms.', 'Mrs.', 'Mr.']},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Customer Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Customer Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customerOption,"label":"name","placeholder":"None"},on:{"input":function($event){return _vm.handleCustomer($event)}},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('label'),_c('label'),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"height":"82px"}},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.handlePopUp}},[_vm._v(" + ")])],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Address")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Address","state":errors.length > 0 ? false : null,"rows":"1"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Pin Code")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required|digits:6","name":"Pin Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","maxlength":"6","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"type":"number","placeholder":"Enter Pin Code"},on:{"input":function($event){return _vm.clickPincode($event)}},model:{value:(_vm.pincode),callback:function ($$v) {_vm.pincode=$$v},expression:"pincode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("City")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Enter City"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Area")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Enter Area"},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("State")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Enter State"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Country")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("DOB")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"DOB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }},model:{value:(_vm.dateofbirth),callback:function ($$v) {_vm.dateofbirth=$$v},expression:"dateofbirth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Mobile No.")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('b-form-input',{attrs:{"disabled":"","maxlength":"10","placeholder":"Enter Mobile No."},model:{value:(_vm.mobileNo),callback:function ($$v) {_vm.mobileNo=$$v},expression:"mobileNo"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Email ID")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required|email","name":"Email ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Enter Email ID"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Aadhar No")]),_c('b-form-input',{attrs:{"maxlength":"14","placeholder":"Enter Aadhar No"},on:{"input":function($event){return _vm.validateAadharClick($event)}},model:{value:(_vm.adharno),callback:function ($$v) {_vm.adharno=$$v},expression:"adharno"}}),(_vm.adharnoValidate == false)?_c('small',{staticClass:"text-danger"},[_vm._v("Enter Valid Aadhar No")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Pan No")]),_c('b-form-input',{attrs:{"maxlength":"10","placeholder":"Enter PAN No"},on:{"input":function($event){return _vm.validatePanClick($event)}},model:{value:(_vm.panno),callback:function ($$v) {_vm.panno=$$v},expression:"panno"}}),(_vm.pannoValidate == false)?_c('small',{staticClass:"text-danger"},[_vm._v("Enter Valid PAN No")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nominee")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nominee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Nominee"},model:{value:(_vm.nominee),callback:function ($$v) {_vm.nominee=$$v},expression:"nominee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nominee Relationship")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nominee Relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Nominee Relationship"},model:{value:(_vm.nomineerelation),callback:function ($$v) {_vm.nomineerelation=$$v},expression:"nomineerelation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nominee Phone")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Nominee Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"10","state":errors.length > 0 ? false : null,"placeholder":"Enter Nominee Phone"},model:{value:(_vm.nomineephone),callback:function ($$v) {_vm.nomineephone=$$v},expression:"nomineephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nominee DOB")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                dateFormat: 'd/m/Y',
                defaultDate: new Date(),
                maxDate: new Date(),
              }},model:{value:(_vm.nomineedob),callback:function ($$v) {_vm.nomineedob=$$v},expression:"nomineedob"}})],1)],1),_c('b-col',{style:(_vm.status == 'Yes' ? '' : 'display: none'),attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Remarks")]),_c('b-form-input',{attrs:{"placeholder":"Enter remarks"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag || _vm.submitDisable},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")]),(_vm.$route.params.id && _vm.userData == 'admin' && _vm.status == 'Yes')?_c('b-button',{staticClass:"ml-1",attrs:{"disabled":this.active == 0,"variant":"danger"},on:{"click":_vm.handleApprove}},[_vm._v("Cancel ")]):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"modal-form","size":"lg","cancel-variant":"outline-secondary","ok-title":"Submit","cancel-title":"Close","title":"Add Customer","hide-footer":""},model:{value:(_vm.popUpVisible),callback:function ($$v) {_vm.popUpVisible=$$v},expression:"popUpVisible"}},[_c('add-customer',{attrs:{"fromSales":true,"selectedUser":_vm.selectedteam,"closePopUp":_vm.closePopUp,"getCustomer":_vm.getCustomer}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }