<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col :style="userData == 'admin' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Sales By</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="userData == 'admin' ? 'required' : ''"
                name="Sales By"
              >
                <v-select
                  placeholder="None"
                  v-model="selecttype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="selecttypee($event)"
                  label="name"
                  :options="['Employee', 'ChannelPartner', 'Franchise', 'Direct']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :style="selecttype != 'Direct' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Select {{ selecttype }}</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  userData == 'employee' || selecttype == 'Employee' ? 'required' : ''
                "
                name="Employee"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedteam"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="teamOption"
                  @input="userInfo($event)"
                >
                  <template #option="{ name, profile_image, username, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                    ><span>({{ username }})</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Booking Date</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Booking Date"
              >
                <flat-pickr
                  v-model="bookingdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Booking Type </label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Booking Type"
              >
                <v-select
                  placeholder="None"
                  @input="handelClickBooking($event)"
                  v-model="bookingtype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Full Amount', 'Part Payment']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" :style="userDetails ? '' : 'display:none'">
            <b-row>
              <b-col md="3">
                <label>Email</label>
                <b-form-input disabled v-model="userDetails.email"
              /></b-col>
              <b-col md="3">
                <label>Phone No</label>
                <b-form-input disabled v-model="userDetails.mobile"
              /></b-col>
              <b-col md="3">
                <label>Username</label>
                <b-form-input disabled v-model="userDetails.username"
              /></b-col>
              <b-col md="3">
                <label>Photo :</label>
                <b-media-aside>
                  <b-link>
                    <b-img
                      rounded
                      :src="
                        userDetails.profile_image
                          ? userDetails.profile_image
                          : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      "
                      height="80"
                    />
                  </b-link> </b-media-aside
              ></b-col>
              <!-- <b-col md="3">
                <label>Pancard No</label>
                <b-form-input disabled v-model="userDetails.panno"
              /></b-col> -->
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Application Form" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>In SIR?</label>
              <v-select
                placeholder="None"
                v-model="sir"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                @input="handleSIR"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Village Name (New Survay No.)</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Village Name"
              >
                <v-select
                  placeholder="None"
                  v-model="village"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="villagename"
                  @input="getVillageById($event)"
                  :options="villageOption"
                >
                  <template #option="{ villagename, surveynonew }">
                    <span class="font-weight-bolder"> {{ villagename }} </span
                    ><span>({{ surveynonew }})</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="sir == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>Zone</label>
              <!-- <v-select
                placeholder="None"
                v-model="zone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="zoneOptions"
              /> -->
              <b-form-input placeholder="Zone" v-model="zone.name" />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="sir == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>Selelct T.P.</label>
              <!-- <v-select
                placeholder="None"
                v-model="tpno"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                @input="handleSubTP"
                :options="[1, 2, 3, 4, 5, 6]"
              /> -->
              <b-form-input placeholder="Tp No." v-model="tpno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="sir == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>Selelct Sub T.P.</label>
              <!-- <v-select
                placeholder="None"
                v-model="subtpno"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="subtpOptions"
                @input="handleSubTp"
              /> -->
              <b-form-input placeholder="Sub Tp No." v-model="subtpno" disabled />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Survey No. New</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Survey No. New"
              >
                <!-- <v-select
                  v-model="newsurveyno"
                  placeholder="None"
                  label="surveynonew"
                  :options="newsurveynoOption"
                  @input="surveyNo($event)"
                /> -->
                <b-form-input
                  placeholder="New Survay No."
                  v-model="newsurveyno"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Old Survay No.</label>

              <b-form-input placeholder="Old Survay No." v-model="oldsurveyno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>F.P. No.</label>
              <b-form-input
                placeholder="Enter F.P. No."
                v-model="fpno"
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>F.P. Area /Land size (In Sq. Yard)</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Land Size"
              >
                <b-form-input
                  v-model="fparea"
                  placeholder="Enter F.P. Area"
                  type="number"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="3">
            <b-form-group>
              <label>Land Map</label>
              <b-form-file v-model="landmap" placeholder="Select Document" drop-placeholder="Drop file here..."
                @input="handleMultiFileChange($event, 'landsales', 'landmap')" />
            </b-form-group>
            <attachment :data="landmap" />
          </b-col> -->
          <b-col md="12">
            <b-card-code title="Rate Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>CP Rate</label>
              <b-form-input disabled v-model="cprate" placeholder="Enter Rate" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Bulk Land Rate</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bulk Land Rate"
              >
                <b-form-input
                  disabled
                  v-model="landrate"
                  placeholder="Enter Rate"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group>
              <label>With NA?</label>

              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="With NA">
                <v-select
                  disabled
                  placeholder="None"
                  v-model="withna"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Yes', 'No']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" :style="withna == 'No' ? '' : 'display:none'">
            <b-form-group>
              <label>NA Charges Per Bigha</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="withna == 'No' ? 'required' : ''"
                name="Charges Per Bigha"
              >
                <b-form-input
                  v-model="nacharges"
                  disabled
                  placeholder="Enter NA Charges"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="3">
            <b-form-group>
              <label>T.P. Road</label>
              <b-form-input v-model="tproad" placeholder="Enter T.P. Area" />
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="3">
            <b-form-group>
              <label>Bulk Land Area(Sq. Yard)</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bulk LandArea(Sq. Yard)"
              >
                <b-form-input
                  disabled
                  v-model="landareasqyard"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bulk Land Area(Sq. Yard)"
                  @input="handleLandArea('yd')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Bulk Land Area(Sq.Feet)</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bulk Land Area(Sq.Feet)"
              >
                <b-form-input
                  disabled
                  v-model="landareasqfeet"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bulk Land Area(Sq.Feet)"
                  @input="handleLandArea('ft')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="3">
            <b-form-group>
              <label>Owner's Rate</label>
              <b-form-input v-model="ownerrate" placeholder="Enter T.P. Area" />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>CP Final Rate</label>
              <b-form-input
                v-model="cpfinalrate"
                placeholder="Enter T.P. Area"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Customer Rate</label>
              <b-form-input
                v-model="customerrate"
                placeholder="Enter T.P. Area"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="3">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea
                id="remarks"
                placeholder="Enter remarks"
                v-model="remarks"
                rows="1"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Land Jantri Rate/ Govt. Guideline Rs.</label>
              <b-form-input
                v-model="jantrirate"
                disabled
                placeholder="Enter Land Jantri Rate"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Payment Type</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Type"
              >
                <v-select
                  placeholder="None"
                  v-model="paymenttype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Down Payment', 'Token']"
                  @input="handleToken"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" :style="paymenttype == 'Token' ? '' : 'display:none'">
            <b-form-group>
              <label>Payment Token</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="paymenttype == 'Token' ? 'required' : ''"
                name="Payment Token"
              >
                <b-form-input
                  v-model="paymenttoken"
                  placeholder="Enter Payment Token"
                  @input="handleToken"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Payment Terms Days</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Terms Days"
              >
                <b-form-input
                  v-model="paymenttermday"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Payment Terms Days"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Payment Through</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Through"
              >
                <v-select
                  placeholder="None"
                  v-model="paymentthrough"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="paymentThroughOption"
                  @input="handleSelectPayment"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Payment Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank Name"
              >
                <b-form-input
                  v-model="bankname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bank Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Reference Number</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Reference Number"
              >
                <b-form-input
                  v-model="refno"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Reference Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Upload Receipt</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Upload Receipt "
              >
                <b-form-file
                  v-model="bankreceipt"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="handleMultiFileChange($event, 'sales', 'receipt')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="bankreceipt" />
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Date">
                <flat-pickr
                  v-model="bankdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Remarks</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Remarks">
                <b-form-textarea
                  id="Remarks"
                  v-model="bankremarks"
                  :state="errors.length > 0 ? false : null"
                  rows="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Cheque No</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque No"
              >
                <b-form-input
                  v-model="chequeno"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Cheque No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank Name"
              >
                <b-form-input
                  v-model="bankname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bank Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Cheque Date:</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque Date"
              >
                <flat-pickr
                  v-model="chequedate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Status</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Status">
                <v-select
                  placeholder="None"
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Pending', 'Bounce', 'Clear']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12"> </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Total Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Payment"
              >
                <b-form-input
                  v-model="totalpayment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Total Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>First Payment</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="firstPaymentRules"
                name="First Payment"
              >
                <b-form-input
                  v-model="firstpayment"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter First Payment"
                  @input="handleRemaining"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <small class="text-danger">{{
                  errors[0] == "The First Payment field must be 10000 or more"
                    ? "The First Payment field must be accept more than 5 figure Amount"
                    : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remaining Payment</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Remaining Payment"
              >
                <b-form-input
                  v-model="remainingpayment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Remaining Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
            <!-- <b-col md="12"> -->
            <b-card-code title="INSTALLMENTS" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>

          <b-col md="12" :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
            <!-- <b-col md="12"> -->
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th></th>
                  <th>Installment date</th>
                  <th>Installment Amount</th>
                  <th>Remarks</th>
                  <th :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(installment, index) in installments" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <flat-pickr
                      v-model="installment.installment_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        defaultDate: new Date(),
                      }"
                      style="background-color: transparent"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model="installment.installment_amount"
                      placeholder="Enter Installment Amount"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model="installment.remarks"
                      placeholder="Enter Installment Remarks"
                    />
                  </td>
                  <td :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
                    <b-button
                      variant="outline-danger"
                      class="mt-0"
                      @click="deleteRow(index)"
                    >
                      <feather-icon icon="TrashIcon" class="" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button
              :style="bookingtype == 'Part Payment' ? '' : 'display:none'"
              variant="primary"
              class="mt-1"
              @click="addRow"
            >
              Add more
            </b-button>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group>
              <label>Ledger</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Number"
              >
                <v-select placeholder="None"
                  v-model="selectedLedger"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="ledgerOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-card-code title="Customer Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Title</label>
              <v-select
                placeholder="None"
                v-model="title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Shri.', 'Smt.', 'Ms.', 'Mrs.', 'Mr.']"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Customer Name</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Customer Name"
                  >
                    <v-select
                      v-model="customer"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerOption"
                      label="name"
                      @input="handleCustomer($event)"
                      placeholder="None"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <label></label>
              <label></label>
              <div class="d-flex align-items-center" style="height: 82px">
                <b-button variant="primary" size="sm" @click="handlePopUp"> + </b-button>
              </div>
            </b-row>
          </b-col>
          <!-- <b-col md="3">
            <b-form-group>
              <label>Middle Name</label>
              <validation-provider #default="{ errors }" rules="required" name="Middle Name">
                <b-form-input v-model="cmiddlename" :state="errors.length > 0 ? false : null"
                  placeholder="Enter Middle Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="3">
            <b-form-group>
              <label>Last Name</label>
              <validation-provider #default="{ errors }" rules="required" name="Last Name">
                <b-form-input v-model="clastname" :state="errors.length > 0 ? false : null"
                  placeholder="Enter Last Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="3">
            <b-form-group>
              <label>Address</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Address">
                <b-form-textarea
                  id="Address"
                  v-model="address"
                  :state="errors.length > 0 ? false : null"
                  rows="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Pin Code"
              >
                <b-form-input
                  disabled
                  v-model="pincode"
                  maxlength="6"
                  @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <!-- <v-select disabled v-model="city" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOption" @input="clickArea($event)" placeholder="None" label="City" /> -->
                <b-form-input
                  disabled
                  v-model="city"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter City"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Area">
                <!-- <v-select disabled v-model="area" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="areaOption"
                  placeholder="None" @input="handlePincode()" label="PostOfficeName" /> -->
                <b-form-input
                  disabled
                  v-model="area"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Area"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <!-- <v-select disabled v-model="state" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="stateOptions" label="State" @input="handleCity()" /> -->
                <b-form-input
                  disabled
                  v-model="state"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter State"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Country</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" name="Country">
                <b-form-input
                  v-model="country"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Country"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="3">
            <b-form-group>
              <label>State</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <b-form-input v-model="state" :state="errors.length > 0 ? false : null" placeholder="Enter State" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <b-form-input v-model="city" :state="errors.length > 0 ? false : null" placeholder="Enter City" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Pincode</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pincode">
                <b-form-input v-model="pincode" maxlength="6" :state="errors.length > 0 ? false : null"
                  placeholder="Enter Pincode" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>DOB</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="DOB">
                <flat-pickr
                  v-model="dateofbirth"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile No.</label>
              <label style="color: red !important">*</label>

              <b-form-input
                disabled
                v-model="mobileNo"
                maxlength="10"
                placeholder="Enter Mobile No."
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email ID</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email ID"
              >
                <b-form-input
                  disabled
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Email ID"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <b-form-input
                v-model="adharno"
                maxlength="14"
                @input="validateAadharClick($event)"
                placeholder="Enter Aadhar No"
              />
              <small v-if="adharnoValidate == false" class="text-danger"
                >Enter Valid Aadhar No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                maxlength="10"
                @input="validatePanClick($event)"
                placeholder="Enter PAN No"
              />
              <small v-if="pannoValidate == false" class="text-danger"
                >Enter Valid PAN No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Nominee">
                <b-form-input
                  v-model="nominee"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Nominee"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Relationship</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Nominee Relationship"
              >
                <b-form-input
                  v-model="nomineerelation"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Nominee Relationship"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Phone</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" name="Nominee Phone">
                <b-form-input
                  v-model="nomineephone"
                  maxlength="10"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Nominee Phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee DOB</label>
              <flat-pickr
                v-model="nomineedob"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" :style="status == 'Yes' ? '' : 'display: none'">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" placeholder="Enter remarks" />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group>
              <label style="font-size: 16px">Send:&nbsp;</label>
              <b-form-checkbox v-model="sendemail" inline>
                Email
              </b-form-checkbox>
              <b-form-checkbox v-model="sendsms" inline> SMS </b-form-checkbox>
              <b-form-checkbox v-model="sendwhatsapp" inline>
                Whatsapp
              </b-form-checkbox>

            </b-form-group>
          </b-col> -->

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag || submitDisable"
              @click.prevent="submitForm"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
            <b-button
              v-if="$route.params.id && userData == 'admin' && status == 'Yes'"
              :disabled="this.active == 0"
              class="ml-1"
              variant="danger"
              @click="handleApprove"
              >Cancel
            </b-button>
          </b-col>
        </b-row>
        <b-modal
          id="modal-form"
          v-model="popUpVisible"
          size="lg"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="Add Customer"
          hide-footer
        >
          <add-customer
            :fromSales="true"
            :selectedUser="selectedteam"
            :closePopUp="closePopUp"
            :getCustomer="getCustomer"
          />
        </b-modal>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AddCustomer from "../customer/AddCustomer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BImg,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Attachment from "../../../components/Attechment.vue";
import image from "../../../assets/images/pages/no-image.png";

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    AddCustomer,
    flatPickr,
    BFormCheckbox,
    PinchScrollZoom,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormFile,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      selecttype: "",
      selectedteam: "",
      bookingdate: moment(new Date()).format("DD/MM/yyyy"),
      bookingtype: "",
      zone: "",
      tpno: "",
      subtpno: "",
      village: "",
      newsurveyno: "",
      oldsurveyno: "",
      fpno: "",
      fparea: "",
      landmap: "",
      cprate: "",
      landrate: "",
      withna: "",
      nacharges: "",
      landareasqyard: "",
      landareasqfeet: "",
      remarks: "",
      jantrirate: "",
      paymenttype: "",
      paymenttoken: "",
      paymenttermday: "",
      paymentthrough: "",
      bankreceipt: "",
      bankdate: moment(new Date()).format("DD/MM/yyyy"),
      bankremarks: "",
      refno: "",
      chequeno: "",
      bankname: "",
      chequedate: moment(new Date()).format("DD/MM/yyyy"),
      status: "",
      installments: [],
      totalpayment: "",
      firstpayment: "",
      remainingpayment: "",
      title: "",
      customer: "",
      address: "",
      pincode: "",
      city: "",
      area: "",
      state: "",
      country: "India",
      dateofbirth: moment(new Date()).format("DD/MM/yyyy"),
      mobileNo: "",
      email: "",
      adharno: "",
      panno: "",
      nominee: "",
      nomineerelation: "",
      nomineephone: "",
      nomineedob: moment(new Date()).format("DD/MM/yyyy"),
      chequeField: false,

      active: 1,
      visible: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      teamOption: [],
      paymentThroughOption: [
        "By Cheque",
        "By RTGS",
        "By NEFT",
        "By ECS",
        "Debit/Credit Card",
        "Net Banking",
        "By UPI",
        "Other",
      ],
      firstPaymentRules: "required|min_value:10000",
      ctitleOption: [
        { name: "Shri." },
        { name: "Smt." },
        { name: "Ms." },
        { name: "Mrs." },
        { name: "Mr." },
      ],
      areaOption: [],
      cityOption: [],
      adharnoValidate: true,
      pannoValidate: true,
      ifEdit: false,
      noChequeField: false,
      userData: "",
      userName: "",
      flag: false,
      submitDisable: false,
      dashboard: false,
      fromState: "",
      stateOptions: [],
      StateData: [],
      customerOption: [],
      popUpVisible: false,
      userDetails: "",
      parent: "",
      zoneOptions: [],
      newsurveynoOption: [],
      allVillageOption: [],
      villageOption: [],
      subtpOptions: [],
      subTP: {
        1: ["1A-1", "1A-2", "1A-3", "1A-4", "1A-5", "1B"],
        2: ["2A", "2B-1", "2B-2", "2B-3", "2B-4", "2B-5", "2B-6"],
        3: ["3A", "3B", "3C", "3D"],
        4: ["4A", "4B-1", "4B-2"],
        5: ["5A", "5B", "5C-1", "5C-2"],
        6: ["6A", "6B"],
      },
      sir: "",
      singleVillages: [],
      loginData: {},
      imagePath: process.env.VUE_APP_IMAGE_PATH,
    };
  },
  destroyed() {
    localStorage.removeItem("landData");
  },
  async mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      rights.map((item) => {
        if (item.modulename == "Land Sales") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/landsales");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/landsales");
          }
        }
      });
    }
    this.getState();
    await this.getVillage();
    // await this.getZone();

    this.loginData = JSON.parse(localStorage.getItem("userData"));
    this.userData = this.loginData.role;
    this.userName = this.loginData.name;
    this.userId = this.loginData.id;
    this.init();

    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleSIR(e) {
      let villagesSir = [];
      let villages = [];

      this.allVillageOption.map((item) => {
        if (item.tpno) {
          villagesSir.push(item);
        } else {
          villages.push(item);
        }
      });
      if (e == "Yes") {
        this.villageOption = villagesSir;
      } else if (e == "No") {
        this.villageOption = villages;
      }
    },
    handleSubTp() {
      this.villageOption = [];
      this.allVillageOption.map((item) => {
        if (item.subtpno == this.subtpno && item.is_purchase == 1 && item.is_sales == 0) {
          this.villageOption.push(item);
        }
      });
      const resArr = [];
      this.villageOption.filter(function (item) {
        const i = resArr.findIndex((x) => x.villagename == item.villagename);
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      this.villageOption = resArr;
    },
    // async getZone() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/zone`,
    //   };
    //   await axios(requestOptions).then((response) => {
    //     this.zoneOptions = response.data.data.data;
    //   });
    // },
    // handleSubTP() {
    //   this.subtpno = "";
    //   this.subtpOptions = this.subTP[this.tpno];
    // },
    getVillageById(e) {
      if (e) {
        // this.newsurveynoOption = [];
        this.newsurveyno = e.surveynonew;
        this.oldsurveyno = e.surveynoold;
        this.tpno = e.tpno;
        this.subtpno = e.subtpno;
        this.fpno = e.fpno;
        this.fparea = e.isfp == "Yes" ? e.fparea : e.totalarea;
        this.cprate = e.cprate;
        this.withna = e.withna;
        this.nacharges = e.nacharges;
        this.landrate = e.marketrate;
        this.totalpayment = this.landrate * this.fparea;
        this.zone = e.zone ? e.zone : "";
        this.jantrirate = e.Jantrirate;
      }
      this.handleToken();
    },
    //
    async getVillage(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/landmaster?pagesize=10000&pagenum=1`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allVillageOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handlevillageAtEdit(id) {
      this.allVillageOption.map((item) => {
        if (item.id == id) {
          this.village = item;
          this.villageOption.push(item);
          this.getVillageById(this.village);
          this.newsurveyno = item;
          this.newsurveynoOption.push(item);
        }
      });
      this.villageOption.map((item, index) => {
        if (item.name == this.village.villagename) {
          this.villageOption[index] = this.village;
        }
      });
    },
    handleRemaining() {
      this.remainingpayment = this.totalpayment - this.firstpayment;
    },
    handleToken() {
      let minValue;
      if (this.paymenttype == "Down Payment") {
        minValue = (this.totalpayment * 25) / 100;
        this.firstPaymentRules = `required|min_value:${minValue}`;
      } else if (this.paymenttype == "Token") {
        minValue = this.paymenttoken;
        this.firstPaymentRules = `required|min_value:${minValue}`;
      }
      this.handleRemaining();
    },
    // handleNA() {
    //   this.totalpayment =
    //     this.landrate * this.fparea + this.fparea * this.nacharges;
    // },
    handleLandArea(type) {
      if (type == "yd") {
        this.landareasqfeet = parseFloat((this.landareasqyard / 0.333).toFixed(3));
      } else {
        this.landareasqyard = parseFloat((this.landareasqfeet * 0.333).toFixed(3));
      }
    },
    async userInfo(e) {
      let id = null;
      if (e && e.id) {
        id = e.id;
      } else {
        id = e;
      }
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.userDetails = response.data.data[0];
        if (this.userDetails.role !== "admin") {
          // this.userDetails.parent;
        }
        this.getCustomer(this.userDetails.id);
      });
    },
    // async getParent(id) {
    //   const requestoption = {
    //     method: "GET",
    //     url: `${this.baseApi}/getusersbyid/${id}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   };
    //   await axios(requestoption).then((response) => {
    //     this.parent = response.data.data[0].name;
    //   });
    // },
    closePopUp() {
      this.popUpVisible = false;
    },
    handlePopUp() {
      if (this.selectedteam || this.selecttype == "Direct") {
        this.popUpVisible = true;
      } else {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Select User !",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    async getCustomer(id) {
      const data = {
        assignto: id,
      };
      const requestoption = {
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      };
      await axios(requestoption).then((response) => {
        this.customerOption = response.data.data;
      });
    },
    async getCustomerDetails(e) {
      if (e) {
        const requestoption = {
          method: "GET",
          url: `${this.baseApi}/getusersbyid/${e}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        };
        await axios(requestoption).then((response) => {
          const details = response.data.data[0];
          this.customer = details;
          this.handleCustomer(details);
        });
      }
    },
    handleCustomer(details) {
      this.email = details.email;
      this.mobileNo = details.mobile;
      this.dateofbirth =
        details.birthdate == "00/00/0000" || details.birthdate == null
          ? null
          : details.birthdate;
      this.pincode = details.curpincode;
      this.area = details.curarea;
      this.city = details.curcity;
      this.state = details.curstate;
      this.adharno = details.aadharcardno;
      this.panno = details.panno;
      this.address = details.curaddressline1 + " " + details.curaddressline2;
      this.nominee = details.nomineename;
      this.nomineerelation = details.nomineerelation;
      this.nomineephone = details.nomineephone;
      this.nomineedob = details.nomineedob;
      this.title = details.title;
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    selecttypee(e) {
      this.selecttype = e;
      this.selectedteam = null;
      if (this.selecttype == "Direct") {
        this.selectedteam = {
          id: this.userId,
        };
        this.userInfo(this.userId);
        this.getCustomer(this.userId);
      } else if (this.selecttype == "ChannelPartner") {
        this.getChannelPartner();
      } else if (this.selecttype == "Franchise") {
        this.getFranchise();
      } else if (this.selecttype == "Employee") {
        this.getTeam();
      }
    },
    async handleMultiFileChange(e, type, name) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (name == "receipt") {
            this.bankreceipt = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else {
            this.landmap = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickBooking(e) {
      this.bookingtype = e;
      if (e == "Part Payment") {
        this.handlePartPayment();
      }
      this.$forceUpdate();
    },
    handlePartPayment() {
      this.installments = [
        {
          installment_date: moment(new Date()).format("DD/MM/YYYY"),
          installment_amount: 0,
          remarks: "",
          type: "Part Payment",
        },
      ];
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandsalesById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.selecttype = item.selecttype;
      this.selectedteam = item.selectedteam ? item.selectedteam : {};
      this.userDetails = item.selectedteam ? item.selectedteam : {};

      this.bookingdate = item.bookingdate;
      this.bookingdate =
        item.bookingdate == "00/00/0000" || item.bookingdate == null
          ? null
          : item.bookingdate;
      this.bookingtype = item.bookingtype;

      this.zone = item.zone;
      this.tpno = item.tpno;
      this.subtpno = item.subtpno;
      this.village = item.village;
      this.newsurveyno = item.newsurveyno;
      this.oldsurveyno = item.oldsurveyno;
      this.fpno = item.fpno;
      this.fparea = item.fparea;
      // this.landmap = item.landmap;

      this.cprate = item.cprate;
      this.landrate = item.landrate;
      this.withna = item.withna;
      this.nacharges = item.nacharges;
      this.landareasqyard = item.landareasqyard;
      this.landareasqfeet = item.landareasqfeet;
      this.remarks = item.remarks;
      this.jantrirate = item.jantrirate;
      this.paymenttype = item.paymenttype;
      this.paymenttoken = item.paymenttoken;
      this.paymenttermday = item.paymenttermday;
      this.paymentthrough = item.paymentthrough;

      this.totalpayment = item.totalpayment;
      this.firstpayment = item.firstpayment;
      this.remainingpayment = item.remainingpayment;

      this.bankname = item.bankname;
      this.refno = item.refno;
      this.bankreceipt = item.bankreceipt;
      this.bankdate = item.bankdate;
      this.bankremarks = item.bankremarks;
      this.chequedate = item.chequedate;
      this.chequeno = item.chequeno;
      this.status = item.status;
      this.customer = item.customer ? item.customer : {};
      this.installments = item.installments;
      this.sir = item.subtpno ? "Yes" : "No";

      this.getCustomer(this.selectedteam.id);
      this.address = this.address;
      this.handleCustomer(this.customer);

      this.handleCity();
      this.handleArea();
      this.handleSelectPayment(item.paymentthrough);
      this.handleSIR(this.sir);
    },
    handleSelectPayment(e) {
      const name = e;
      if (name == "By Cheque") {
        this.chequeField = true;
      } else {
        this.chequeField = false;
      }
      if (name !== "Other" && name !== "By Cheque" && name !== "By UPI") {
        this.noChequeField = true;
      } else {
        this.noChequeField = false;
      }
    },

    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      //   this.areaOption = []
      // this.StateData.map((item) => {
      //   if (item.City == e) {
      //     this.areaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.cityOption = [];
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.adharno.length == 4 ? (this.adharno = this.adharno.concat(" ")) : this.adharno;
      this.adharno.length == 9 ? (this.adharno = this.adharno.concat(" ")) : this.adharno;
      if (regex.test(this.adharno) == true) {
        this.adharnoValidate = true;
      } else {
        this.adharnoValidate = false;
      }
    },

    onClickBack() {
      this.$router.push("/crm/bulklandsales");
    },
    submitForm(e) {
      let part = true;
      let data;
      const data1 = {
        selecttype: this.selecttype,
        selectedteam: this.selectedteam.id,
        bookingdate: this.bookingdate,
        bookingtype: this.bookingtype,
        zone: this.zone.id,
        tpno: this.tpno,
        subtpno: this.subtpno,
        village: this.village.id,
        newsurveyno: this.newsurveyno,
        oldsurveyno: this.oldsurveyno,
        fpno: this.fpno,
        fparea: this.fparea,
        // landmap: this.landmap,
        cprate: this.cprate,
        landrate: this.landrate,
        withna: this.withna,
        nacharges: this.nacharges,
        landareasqyard: this.landareasqyard,
        landareasqfeet: this.landareasqfeet,
        remarks: this.remarks,
        jantrirate: this.jantrirate,
        paymenttype: this.paymenttype,
        paymenttoken: this.paymenttoken,
        paymenttermday: this.paymenttermday,
        paymentthrough: this.paymentthrough,
        totalpayment: this.totalpayment,
        firstpayment: this.firstpayment,
        remainingpayment: this.remainingpayment,
        bankname: this.bankname,
        refno: this.refno,
        bankreceipt: this.bankreceipt,
        bankdate: this.bankdate,
        bankremarks: this.bankremarks,
        chequedate: this.chequedate,
        chequeno: this.chequeno,
        status: this.status,
        customer: this.customer.id,
      };
      if (this.bookingtype == "Part Payment") {
        const installment = {
          installments: this.installments,
        };
        data = {
          ...data1,
          ...installment,
        };
      } else {
        data = data1;
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.adharnoValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (this.bookingtype == "Part Payment") {
          let total = parseFloat(this.firstpayment);
          this.installments.map((item) => {
            total += parseFloat(item.installment_amount);
          });
          if (parseFloat(total.toFixed(2)) !== parseFloat(this.totalpayment)) {
            part = false;
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Installmant amount should be equal to total payment",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
        if (
          success &&
          this.pannoValidate == true &&
          this.adharnoValidate == true &&
          part == true
        ) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/landsales/${this.$route.params.id}`
              : `${baseApi}/landsales`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = true;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : json.data.success
                  ? "Update SuccessFully"
                  : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/crm/bulklandsales");
            })
            .catch((error) => {
              this.submitDisable = false;
              let message = "";

              const code = error.toString().includes("409");
              if (code) {
                for (let key in error.response.data.message.original) {
                  message += error.response.data.message.original[key][0] + " ";
                }
              }
              this.$swal({
                title: "Error!",
                text: message ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    getLandData() {
      let data = JSON.parse(localStorage.getItem("landData"));

      this.village = data.village;
      this.sir = data.sir;
      this.userDetails = this.loginData;
      this.selectedteam = this.loginData;
      if (this.selectedteam.role == "channelpartner") {
        this.selecttype = "Channel Partner";
      } else if (this.selectedteam.role == "franchise") {
        this.selecttype = "Franchise";
      } else if (
        this.selectedteam.role == "employee" &&
        this.selectedteam.type != "Account Assistant"
      ) {
        this.selecttype = "Employee";
      } else if (
        this.selectedteam.role == "admin" ||
        this.selectedteam.type == "Account Assistant"
      ) {
        this.selecttype = "Direct";
      }
      this.getVillageById(this.village);
    },
    async init() {
      if (localStorage.getItem("landData")) {
        this.getLandData();
      }
      if (this.userData == "channelpartner") {
        this.downlineCP();

        this.selecttype = "ChannelPartner";
      } else if (this.userData == "employee") {
        this.selectedteam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedteam);
      } else if (this.userData == "franchise") {
        this.selectedteam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedteam);
      }
      // this.getLedger()
      // this.getCustomer();
    },
    async downlineCP() {
      this.teamOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.loginData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = [this.loginData];
          response.data.data.map((item) => {
            this.teamOption.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(childs) {
      childs.map((item) => {
        this.teamOption.push(item);
        if (item.children.length > 0) {
          this.handleChild(item.children);
        }
      });
    },
    getEmp(id) {
      // this.selecttype = 'ChannelPartner'
      if (this.selecttype == "ChannelPartner") {
        this.getChannelPartner(id);
      } else if (this.selecttype == "Franchise") {
        this.getFranchise(id);
      } else if (this.selecttype == "Employee") {
        this.getTeam(id);
      } else {
        this.selectedteam = {
          id: this.userId,
        };
      }
    },
    async getTeam(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = response.data.data;
          if (id !== undefined) {
            this.teamOption.map((item) => {
              if (item.id == id) {
                this.selectedteam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = response.data.data;
          if (id !== undefined) {
            this.teamOption.map((item) => {
              if (item.id == id) {
                this.selectedteam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFranchise(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = response.data.data;
          if (id !== undefined) {
            this.teamOption.map((item) => {
              if (item.id == id) {
                selectedteam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },

    addRow() {
      let total = parseFloat(this.firstpayment);
      this.installments.map((item) => {
        total += parseFloat(item.installment_amount);
      });
      if (total < parseFloat(this.totalpayment)) {
        let amount = 0;
        this.installments.map((item) => {
          amount += item.installment_amount;
        });
        this.installments.push({
          installment_date: moment(new Date()).format("DD/MM/yyyy"),
          installment_amount: parseFloat(this.remainingpayment) - amount,
          remarks: "",
          type: "Part Payment",
        });
      } else {
        if (total.toFixed(2) > parseFloat(this.totalpayment).toFixed(2)) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount should be equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount is equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    deleteRow(index) {
      this.installments.splice(index, 1);
    },
  },
};
</script>
